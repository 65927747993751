<template>
  <div class="container-base section-container-padding bg-white">
    <div class="myproj-container settings-my-instances-tabs knowledge">
      <knowledge-tabs tab-name="questions"/>
      <b-alert
          :show="dismissCountDown"
          dismissible
          fade
          variant="success"
          @dismissed="checkReturn"
          @dismiss-count-down="countDownChanged"
      >
        Your Q & A has been saved successfully. Q & A Returning to list.
      </b-alert>
      <b-row>
        <b-col class="margin-cards ask-question-result-margin add-new-qapair-container">
          <div class="mr-0 knowledge-qa-header-row ta-left">
            <span class="fz-25 openSans-100">{{ $t('myApps.qaPairs.header.addNewQaPair') }}</span>
          </div>

          <div class="d-flex justify-content-end">
          <span class="fz-14 openSans-100 knowledge-qa-addnewSpan cursor-pointer" @click="backToQuestions()">
            <img src="/assets/images/icon_close.svg" class="knowledge-qa-addnew">
            <span class="ml-2">{{ $t('myApps.qaPairs.cancel') }}</span>
          </span>
          </div>
          <div class="qa-new-card">
            <b-form-input class="fz-16 OpenSans-400 knowledge-qa-new-question mb-2" size="sm"
                          v-model="newQA.inputText" :readonly="waitingAddition || qaSaved"
                          :placeholder="$t('knowledge.questions.question.inputPlaceholder')">
            </b-form-input>

            <div class="qa-add-response">
              <b-card class="mb-2">
              <span class="cursor-pointer margin-tabs-mode" :class="editorMode == 'edit' ? 'active-tab' : ''"
                    @click="editorMode = 'edit'">
                {{ $t('knowledge.questions.response.labels.write') }}
              </span>
                <span class="cursor-pointer margin-tabs-mode" :class="editorMode == 'preview' ? 'active-tab' : ''"
                      @click="editorMode = 'preview'">
                {{ $t('knowledge.questions.response.labels.preview') }}
              </span>
                <v-md-editor v-model="newQA.responseText" class="margin-editor" height="400px" :mode="editorMode"
                             left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code"
                             right-toolbar=""
                             :placeholder="$t('knowledge.questions.question.answerPlaceholder')" rea>
                </v-md-editor>
              </b-card>
            </div>

            <vue-tags-input
                class="ask-tags-input qa-new-tags-input"
                v-model="tagsInput.currentTag"
                :tags="tagsInput.tagsSearch"
                :autocomplete-items="filteredTags"
                :add-only-from-autocomplete="true"
                @tags-changed="newTags => tagsInput.tagsSearch = newTags"/>

            <b-row class="ml-0">
              <img src="/assets/images/icon_smartsearch.svg">
              <span  @click="displaySmartSearch"
                    class="cursor-pointer fz-14 openSans-400 smart-search-button-label">Search Knowledge Base</span>
            </b-row>

            <div class="d-flex justify-content-end">
              <button :disabled="waitingAddition" type="button" @click="saveQAPair"
                      class="btn openSans-600 fz-12 ta-center button-secondary btn-secondary btn-md">
                {{ $t('knowledge.questions.question.buttons.add') }}
              </button>
            </div>
          </div>
        </b-col>
      </b-row>
      <smart-search-modal @useResponses="useResponses" />
    </div>
    <b-overlay :show="waitingAddition" no-wrap>
    </b-overlay>
  </div>
</template>

<script>
import QAService from "@/modules/knowledge/services/QAService.js"
import LibraryService from '@/modules/library/services/LibraryService.js'

import KnowledgeTabs from "@/modules/knowledge/components/KnowledgeTabs.vue"
import SmartSearchModal from "@/modules/knowledge/components/KnowledgeSmartSearchModal.vue"


export default {

  name: 'CreateQAPair',

  components: {
    KnowledgeTabs,
    SmartSearchModal
  },
  data () {
    return {
      newQA: {
        inputText: '',
        responseText: '',
        tags: []
      },
      tagsInput: {
        currentTag: '',
        tagsSearch: []
      },
      botTags: [],
      waitingAddition: false,
      qaSaved: false,
      editorMode: 'edit',
      dismissSecs: 3,
      dismissCountDown: 0
    }
  },
  computed: {
    filteredTags() {
      return this.botTags.filter(tag => {
        return tag.text.toLowerCase().indexOf(this.tagsInput.currentTag.toLowerCase()) !== -1
      })
    }
  },
  methods: {
    useResponses(responses) {
      Array.prototype.forEach.call(responses, response => {
       if (response.passage_path) {
        this.newQA.responseText += response.html
       } else {
        this.newQA.responseText += response.answer
       }
      response.tags.forEach(tag => {
        if(this.tagsInput.tagsSearch.includes(tag) === false) this.tagsInput.tagsSearch.push(tag)
      }) 
      });
    },
    displaySmartSearch(){
        this.$bvModal.show('smartSearchModal')
    },
    getBotTags() {
      LibraryService.getBotTags(this.$router.currentRoute.params.bot).then(response => {
        response.data.forEach(tag => {
          this.botTags.push({text: tag})
        })
      })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    checkReturn() {
      if (this.dismissCountDown==0) {
        this.backToQuestions()
      }
    },
    qaSaveCallback() {
      this.waitingAddition = false
      this.qaSaved = true
      this.dismissCountDown = this.dismissSecs
      this.editorMode = 'preview'
    },
    backToQuestions() {
      this.$router.push(`/${this.$store.getters.getInstance}/my-apps/knowledge/questions`)
    },
    saveQAPair() {
      this.waitingAddition = true
      var tags = this.tagsInput.tagsSearch.map(tag => tag.text ? tag.text : tag)
      let qaData = {
          "input_text": this.newQA.inputText,
          "response_text": this.newQA.responseText,
          "tags": tags
      }
      QAService.save(qaData).then(this.qaSaveCallback)
    }
  },
  created() {
    this.getBotTags()
  }
}
</script>

<style lang="scss" scoped>

.qa-new-card {
  width: 1000px;
  min-height: 420px;
  height: auto;
  margin-top: 15px;
  padding: 29px 29px 31px;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.13), 0 0 2px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
}

.qa-add-response {
  text-align: left;
  margin-left: 0px;
  max-width: 1000px;
  margin-top: 15px;
  margin-bottom: 27px;
}

  ::v-deep {

  @media (min-width: 320px) and (max-width: 1024px) {

      .myproj-container ul.nav.nav-tabs li a.nav-link {
        width: 90px;
        display: flex;
        justify-content: center;
        margin-right: unset;
      }

      .myproj-container ul.nav.nav-tabs li.nav-item:first-of-type {
        margin-left: unset;
      }

      .qa-new-card {
        width: 100%;
        padding: 29px 10px;
      }
    }
 }
</style>